import { useCallback } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
// utils
import useImageCompression from 'src/hooks/useImageCompression';

// components
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { userApi } from 'src/api/user';
import { useUserContext } from 'src/auth/useUserContext';
import PhoneNumberInfoPopover from 'src/sections/auth/PhoneNumberInfoPopover';
import { GetUserDto, UpdateUserDto } from 'woofwoof-api';
import FormProvider, {
  RHFCheckbox,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import { useSnackbar } from '../../../../components/snackbar';

// ----------------------------------------------------------------------

export type AccountGeneralFormValuesProps = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  dob?: string;
  city?: string;
  address?: string;
  zip?: string;
  avatar?: string;
  terms?: boolean;
  marketing?: boolean;
};

type AccountGeneralProps = {
  submitButtonLabel?: string;
  onSubmit?: (values: AccountGeneralFormValuesProps) => void;
  stepValues?: AccountGeneralFormValuesProps | GetUserDto | undefined;
};

export default function AccountGeneral({
  submitButtonLabel = 'Zapisz',
  onSubmit,
  stepValues,
}: AccountGeneralProps) {
  const theme = useTheme();
  const { compressImage, imageLoading } = useImageCompression();
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch, isProfessionalRole } = useUserContext();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (updateUserDto: UpdateUserDto) =>
      userApi.userControllerUpdateUser({ updateUserDto }),
  });
  const { mutateAsync: onboardUser } = useMutation({
    mutationFn: () => userApi.userControllerOnboardUser(),
  });
  const termsSetToFalse =
    data?.agreements && !(data.agreements as { terms: boolean }).terms;

  const UpdateUserSchema: Yup.Schema<AccountGeneralFormValuesProps> =
    Yup.object().shape({
      firstName: Yup.string()
        .matches(/^[A-Za-z ]*$/, 'Podaj poprawne imię')
        .min(2)
        .max(20)
        .required('Imię jest wymagane'),
      lastName: Yup.string()
        .matches(/^[A-Za-z ]*$/, 'Podaj poprawne nazwisko')
        .min(2)
        .max(20)
        .required('Nazwisko jest wymagane'),
      phoneNumber: Yup.string().matches(
        /^(\+48)?[\s-]?(\d{3})[\s-]?(\d{3})[\s-]?(\d{3})$/,
        'Numer telefonu jest nieprawidłowy',
      ),
      dob: Yup.string(),
      city: Yup.string(),
      address: Yup.string(),
      zip: Yup.string().matches(/^\d{2}-\d{3}$/, {
        message: 'Wymagany format to XX-XXX',
        excludeEmptyString: true,
      }),
      avatar: isProfessionalRole
        ? Yup.string().required('Zdjęcie jest wymagane')
        : Yup.string(),
      terms: Yup.boolean().when('$termsSetToFalse', (termsSetToFalse, schema) =>
        termsSetToFalse
          ? schema.isTrue(
              'Akceptacja regulaminu oraz polityki prywatności jest wymagana',
            )
          : schema,
      ),
      marketing: Yup.boolean().when(
        '$termsSetToFalse',
        (termsSetToFalse, schema) =>
          termsSetToFalse ? schema.optional() : schema,
      ),
    });

  const defaultValues = {
    firstName: (stepValues || data)?.firstName || '',
    lastName: (stepValues || data)?.lastName || '',
    phoneNumber: (stepValues || data)?.phoneNumber || '',
    dob: (stepValues || data)?.dob || '',
    city: (stepValues || data)?.city || '',
    address: (stepValues || data)?.address || '',
    zip: (stepValues || data)?.zip || '',
    avatar: (stepValues || data)?.avatar || '',
  };

  const agreements = { marketing: false, terms: false };

  const methods = useForm<AccountGeneralFormValuesProps>({
    resolver: yupResolver(UpdateUserSchema, { context: { termsSetToFalse } }),
    defaultValues: termsSetToFalse
      ? { ...defaultValues, ...agreements }
      : defaultValues,
  });

  const { setValue, handleSubmit, watch } = methods;
  const dob = watch('dob');
  const dobValue = dob ? parseISO(dob) : null;

  const handleOnSubmit = async ({
    dob,
    phoneNumber,
    address,
    city,
    zip,
    avatar,
    firstName,
    lastName,
    terms,
    marketing,
  }: AccountGeneralFormValuesProps) => {
    try {
      if (!data?.onboarded) {
        await onboardUser();
      }
      await mutateAsync({
        firstName,
        lastName,
        phoneNumber,
        dob,
        address,
        city,
        zip: zip || '',
        avatar,
        terms,
        marketing,
      });
      await refetch();
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: 'error',
      });
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const file = await compressImage(acceptedFiles);
        if (file) {
          setValue('avatar', file as string, { shouldValidate: true });
        }
      } catch (error) {
        enqueueSnackbar('Wystąpił błąd', {
          variant: 'error',
        });
      }
    },
    [setValue, enqueueSnackbar, compressImage],
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit || handleOnSubmit)}
    >
      <Grid
        container
        spacing={3}
        sx={{ px: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}
      >
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <Card
            sx={{
              py: 10,
              px: 3,
              textAlign: 'center',
              height: '100%',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: theme.customShadows.z24,
            }}
          >
            {imageLoading ? (
              <Stack
                sx={{
                  height: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    zIndex: 9999,
                    display: 'flex',
                    borderRadius: '50%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 144,
                    width: 144,
                    border: `1px dashed ${alpha(
                      theme.palette.grey[500],
                      0.32,
                    )}`,
                  }}
                >
                  <Box
                    sx={{
                      zIndex: 7,
                      display: 'flex',
                      borderRadius: '50%',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 128,
                      height: 128,
                      color: theme.palette.text.disabled,
                      backgroundColor: theme.palette.primary.lighter,
                      transition: theme.transitions.create('opacity', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }),
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Dozwolony format *.jpeg, *.jpg, *.png, *.gif
                </Typography>
              </Stack>
            ) : (
              <RHFUploadAvatar
                name="avatar"
                onDrop={handleDrop}
                disabled={isLoading}
                helperText={
                  <>
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Dozwolony format *.jpeg, *.jpg, *.png, *.gif
                    </Typography>
                  </>
                }
              />
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={8} xl={8}>
          <Card sx={{ p: 3, boxShadow: theme.customShadows.z24 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                required
                name="firstName"
                label="Imię"
                disabled={
                  isLoading || (data?.onboarded && Boolean(data?.firstName))
                }
              />

              <RHFTextField
                required
                name="lastName"
                label="Nazwisko"
                disabled={
                  isLoading || (data?.onboarded && Boolean(data?.lastName))
                }
              />

              <RHFTextField
                name="phoneNumber"
                label="Numer telefonu"
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneNumberInfoPopover />
                    </InputAdornment>
                  ),
                }}
              />
              <MobileDatePicker
                DialogProps={{
                  sx: (theme) => ({
                    '& .MuiDialogActions-root': { display: 'none' },
                    '& .MuiButtonBase-root.MuiPickersDay-root:focus.Mui-selected':
                      {
                        backgroundColor: theme.palette.primary.main,
                      },
                  }),
                }}
                showToolbar={false}
                label="Data urodzenia"
                closeOnSelect
                inputFormat="dd/MM/yyyy"
                disabled={isLoading}
                value={dobValue}
                maxDate={new Date()}
                onChange={(newValue: any) => {
                  if (newValue) {
                    setValue('dob', newValue?.toISOString() || '');
                  }
                }}
                renderInput={(params) => (
                  <RHFTextField {...params} name="dob" disabled={isLoading} />
                )}
              />

              <RHFTextField name="city" label="Miasto" disabled={isLoading} />

              <RHFTextField name="address" label="Adres" disabled={isLoading} />

              <RHFTextField
                name="zip"
                label="Kod pocztowy"
                disabled={isLoading}
              />
            </Box>

            {termsSetToFalse && (
              <Stack sx={{ paddingLeft: 1, mt: '24px !important' }} spacing={0}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <RHFCheckbox
                    name="terms"
                    label=""
                    disabled={isLoading}
                    customLabel={
                      <Typography variant="body2">
                        Akceptuję{' '}
                        <Link
                          underline="always"
                          color="text.primary"
                          href="https://vetsi.pl/regulamin"
                          target="_blank"
                        >
                          regulamin
                        </Link>{' '}
                        oraz{' '}
                        <Link
                          underline="always"
                          color="text.primary"
                          href="https://vetsi.pl/polityka-prywatnosci"
                          target="_blank"
                        >
                          politykę prywatności.
                        </Link>
                        *
                      </Typography>
                    }
                    sx={{ mr: 1, height: 3 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <RHFCheckbox
                    name="marketing"
                    label=""
                    disabled={isLoading}
                    customLabel={
                      <Typography variant="body2">
                        Wyrażam zgodę na otrzymywanie informacji handlowych od
                        Vetsi drogą elektroniczną na podany adres e-mail.
                      </Typography>
                    }
                    sx={{ mr: 1 }}
                  />
                </Box>
              </Stack>
            )}

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                <span>{submitButtonLabel}</span>
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
